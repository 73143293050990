<template>
  <div
    v-if="opened || transitioningClose"
    class="Menu row-12 relative isolate"
  >

    <Teleport
      to="body"
      class="absolute left-0 top-0 right-0 bottom-0 pointer-events-none"
      tabindex="-1"
    >
      <template v-if="!isSmScreen">
        <div
          v-for="(bar, i) in specialBars"
          :key="i"
          class="bary hidden sm:block"
          :class="{transitioningOpen, transitioningClose, active}"
          :style="{ '--max': `${bar.width}%`, '--pos': `${bar.position}%` }"
        >
          {{ ' ' }}
        </div>
      </template>
      <template v-else>
        <div class="w-full h-full bg-white block sm:hidden" />
      </template>
    </Teleport>

    <VerticalBars />
    <div
      class="main-nav z-10 transition-opacity duration-0 sm:duration-300 ease-in-out"
      :class="[
        isXsScreen ? 'col-8 offset-2' : isSmScreen ? 'col-6 offset-3' : 'col-4 offset-2',
        active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      ]"
    >
      <p class="title">
        Overview.
      </p>
      <div
        v-for="link of navigation"
        :key="link.uri"
        class="link transition-opacity duration-500"
        :class="[active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none']"
        @click="$emit('toggleOff')"
      >
        <CursorWrap cursor="link" :dark="false">
          <Go
            :to="link.uri"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
        </CursorWrap>
      </div>
    </div>

    <div
      class="secondary-nav"
      :class="[
        isXsScreen ? 'col-8 offset-2' : isSmScreen ? 'col-6 offset-3' : 'col-4 ml-18',
        active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      ]"
    >
      <p class="title">
        Featured Shoots.
      </p>
      <div
        v-for="link of subnavigation[0]"
        :key="link.slug"
        class="link transition-opacity duration-0 sm:duration-300 ease-in-out"
        :class="[active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none']"
        @click="$emit('toggleOff')"
      >
        <CursorWrap cursor="link" :dark="false">
          <Go
            :to="link.uri"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
        </CursorWrap>
      </div>
    </div>

    <FooterLinks
      class="z-10 transition-opacity duration-0 sm:duration-300 ease-in-out"
      :class="[active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none']"
      @toggleOff="$emit('toggleOff')"
    />

  </div>
</template>

<script setup>
import FooterLinks from '@/components/UI/FooterLinks';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import VerticalBars from '@/components/UI/VerticalBars';
import CursorWrap from '@/components/utils/CursorWrap.vue';
import { useStore } from 'vuex';
import {
  computed, defineEmits, defineProps, ref, watch,
} from 'vue';

const store = useStore();

const props = defineProps({ opened: Boolean });
const transitioningOpen = ref(false);
const transitioningClose = ref(false);
const active = ref(false);

defineEmits(['toggleOff']);

const isXsScreen = useWithinBreakpoint('xs');
const isSmScreen = useWithinBreakpoint('sm');

const navigation = computed(() => store.state.router.layout?.navigation?.slice?.(1));
const subnavigation = computed(() => {
  const parents = navigation.value.filter((item) => item.children?.length);
  return parents.map((item) => item.children);
});

const specialBars = computed(() => {
  if (isSmScreen.value) {
    return [
      { width: 17.5, position: 0 },
      { width: 50, position: 17.5 },
      { width: 82.5, position: 50 },
    ];
  }
  return [
    { width: 36, position: 0 },
    { width: 14, position: 36 },
    { width: 14, position: 50 },
    { width: 14, position: 64 },
    { width: 22, position: 78 },
  ];
});

watch(() => props.opened, (newVal) => {
  if (newVal) {
    transitioningOpen.value = true;
    setTimeout(() => {
      active.value = true;
      transitioningOpen.value = false;
    }, isSmScreen ? 500 : 0);
  } else {
    transitioningClose.value = true;
    setTimeout(() => {
      active.value = false;
      transitioningClose.value = false;
    }, isSmScreen ? 500 : 0);
  }
});
</script>

<style scoped lang="scss">
.Menu {
  @apply left-0 top-0 z-99 min-h-screen w-full text-blue pb-18;
  @apply relative gap-16 pb-40 sm:gap-20 sm:pb-52 sm:fixed;

  .main-nav {
    @apply flex flex-col font-bold leading-tight gap-11;
    @apply text-xl pt-48 gap-6 sm:pt-64 sm:text-xxl sm:gap-11;

    .title {
      @apply text-sm font-normal -mb-1 leading-tight;
    }
  }

  .secondary-nav {
    @apply flex flex-col font-bold leading-tight;
    @apply pt-0 text-base gap-4 sm:pt-64 sm:text-md sm:gap-8;

    .title {
      @apply text-sm font-normal mb-2 leading-tight;
    }
  }

  .main-nav, .secondary-nav {
    a {
      @apply relative flex items-center transition-all duration-250 delay-75;
    }

    .link {
      @apply relative;

      &:before {
        content: '';
        @apply block h-1.5 bg-blue w-12 absolute top-1/2 -translate-y-full -left-0 transition-opacity opacity-0 duration-200 delay-75;
      }

      &:is(:hover, :focus-visible) {
        a {
          @apply translate-x-14 pl-8;
        }

        &:before {
          @apply opacity-100;
        }
      }
    }
  }

  .secondary-nav .link {
    &:before {
      @apply h-1 w-8;
    }
    &:is(:hover, :focus-visible) a {
      @apply translate-x-10 pl-4;
    }
  }

}

@keyframes bodyFade-in {
  0% {
    max-width: 0;
  }
  100% {
    max-width: var(--max);
  }
}

@keyframes bodyFade-out {
  0% {
    max-width: var(--max);
  }
  100% {
    max-width: 0;
  }
}

.bary {
  @apply inline-block bg-white h-full w-full absolute top-0;
  left: var(--pos);
  max-width: var(--max, 100%);
  &.transitioningOpen {
    animation: bodyFade-in .1s ease-in-out;
    @screen sm {
      animation: bodyFade-in .5s ease-in-out;
    }
  }
  &.transitioningClose {
    animation: bodyFade-out .1s ease-in-out;
    @screen sm {
      animation: bodyFade-out .5s ease-in-out;
    }
  }
  .transitioningClose {
    animation: bodyFade-out .1s ease-in-out;
    @screen sm {
      animation: bodyFade-out .5s ease-in-out;
    }
  }
}
</style>
