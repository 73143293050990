<template>
  <Go
    :to="'/' + $store.state.router.locale"
    aria-label="Home"
    class="Logo CaptureLink"
    @click="toggleOff"
  >
    <span class="circle CaptureLink">97</span>
    <span class="text CaptureLink">group.</span>
  </Go>
</template>

<script>
export default {
  props: {
    dark: Boolean,
  },
  methods: {
    toggleOff() {
      this.$emit('toggleOff');
    },
  },
};
</script>

<style scoped lang="scss">
.Logo {
  @apply flex gap-1 items-center text-md font-bold fixed right-1 top-9 rotate-90 translate-y-full;
  @apply text-blue;
  @include smLess {
    @apply -right-2 top-6;
  }

  .circle {
    @apply p-4 rounded-full leading-none text-white grid place-items-center transition-colors;
    @apply bg-blue text-white;
    //transition: box-shadow 0.25s ease-in-out;
    //box-shadow: 0 0 3px 0 var(--color-white);
  }
  .text {
    @apply -translate-y-1;
    //transition: text-shadow 0.25s ease-in-out;
    //text-shadow: 0 0 3px var(--color-white);
  }

  //&:is(:hover, :focus-visible) {
    //@apply text-blue-light;
    //.circle {
    //  @apply bg-blue-light;
    //}
  //}

  &.dark {
    @apply text-white;
    .circle {
      @apply bg-white text-blue;
      //box-shadow: 0 0 3px 0 var(--color-blue);
    }
    .text {
      //text-shadow: 0 0 3px var(--color-blue);
    }
    //&:is(:hover, :focus-visible) {
    //  @apply text-white-light;
      //.circle {
      //  @apply bg-white-light;
      //}
    //}
  }
}

</style>
