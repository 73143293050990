module.exports = {
  content: [
    './public/**/*.html',
    './src/**/*.vue',
  ],
  safelist: [
    // 'bg-jkblue',
  ],

  theme: {

    colors: {
      transparent: {
        DEFAULT: 'transparent',
      },
      black: {
        DEFAULT: 'var(--color-black)',
        light: 'var(--color-black-50)',
      },
      white: {
        DEFAULT: 'var(--color-white)',
        light: 'var(--color-white-50)',
      },
      blue: {
        DEFAULT: 'var(--color-blue)',
        light: 'var(--color-blue-30)',
      },
      active: {
        DEFAULT: 'var(--color-active)',
      },
    },

    extend: {
      fontFamily: {
        sans: ['Lora', 'sans-serif'],
      },
      spacing: {
        5.5: '1.375rem',
        11: '2.75rem',
        18: '4.5rem',
        38: '9.5rem',
      },
      zIndex: {
        99: '99',
        100: '100',
      },
      transitionDuration: {
        250: '250ms',
      },
    },

    fontSize: {
      sm: '1.25rem', // 20px with 25/35px
      base: '1.5625rem', // 25px (mobile) with 32px
      md: '1.875rem', // 30px with 35px
      lg: '2.5rem', // 40px with 51px
      xl: '2.8125rem', // 45px (mobile) with 57px
      xxl: '3.8125rem', // 61px with 78px
    },

    screens: {
      xs: '425px',
      sm: '650px',
      md: '850px',
      lg: '1080px',
      xl: '1440px',
    },

  },

};
