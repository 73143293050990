<template>
  <div class="bar" :class="{dark}" :style="{'--pos': position, '--h': fullHeight ? height : '100%' }" />
</template>

<script>
export default {
  props: {
    position: String || '0px',
    dark: Boolean,
    fullHeight: Boolean,
  },
  data() {
    return {
      height: '100%',
    };
  },
  mounted() {
    const { body } = document;
    const html = document.documentElement;
    this.height = `${Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    )}px`;
  },
};
</script>

<style scoped lang="scss">
.bar {
  --pos: 0;
  --h: 0;
  height: var(--h);
  width: 100vw;
  max-width: 1px;
  background-color: var(--color-line-light);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: var(--pos);
  transition: all 0.25s ease-in-out;

  &.dark {
    background-color: var(--color-line-dark);
 }
}
</style>
