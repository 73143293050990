<template>
  <div
    class="CursorWrap"
    @mouseenter.stop="enterHandler"
    @mouseleave="leaveHandler"
  >
    <slot />
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';
// 'normal' | 'normal-dark' | 'link' | 'link-dark' | 'video-play' | 'video-pause'

const store = useStore();

const props = defineProps({
  cursor: String,
  dark: Boolean,
});

function computeMove(e) {
  const perc10 = window.innerWidth / 10;
  const half = window.innerWidth / 2;
  const lowerHalf = half - perc10;
  const upperHalf = half + perc10;
  if (e.clientX > lowerHalf && e.clientX < upperHalf) {
    store.commit('theme/cursor', `slide-center${props.dark ? '-dark' : ''}`);
  } else if (e.clientX < half) {
    store.commit('theme/cursor', `slide-left${props.dark ? '-dark' : ''}`);
  } else {
    store.commit('theme/cursor', `slide-right${props.dark ? '-dark' : ''}`);
  }
}

function enterHandler(enterEvent) {
  if (props.cursor !== 'slide') {
    store.commit('theme/cursor', `${props.cursor}${props.dark ? '-dark' : ''}`);
  } else {
    enterEvent.target.addEventListener('mousemove', computeMove);
  }
}

function leaveHandler(leaveEvent) {
  if (props.cursor === 'slide') {
    leaveEvent.target.removeEventListener('mousemove', computeMove);
  }
  store.commit('theme/cursor', `normal${props.dark ? '-dark' : ''}`);
}
</script>

<style scoped lang="scss">

</style>
