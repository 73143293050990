<template>
  <div
    ref="footerLinksRef"
    class="FooterLinks"
    :class="[dark && 'dark', classOverride ? classOverride : isXsScreen ? 'col-8 offset-2' : 'col-6 offset-3']"
  >
    <CursorWrap cursor="link" :dark="dark">
      <Go :to="footer?.instagram" class="link" target="_blank">
        <LogoInstagram />
      </Go>
    </CursorWrap>
    <CursorWrap cursor="link" :dark="false">
      <Go :to="footer?.linkedin" class="link" target="_blank">
        <LogoLinkedIn />
      </Go>
    </CursorWrap>
    <CursorWrap cursor="link" :dark="false">
      <Go :to="footer?.facebook" class="link" target="_blank">
        <LogoFacebook />
      </Go>
    </CursorWrap>

    <div v-if="!isSmScreen" class="footer-right">
      <CursorWrap cursor="link" :dark="false">
        <Go :to="footer?.imprint" @click="toggleOff">
          imprint
        </Go>
      </CursorWrap>
      <CursorWrap cursor="link" :dark="false">
        <Go :to="footer?.privacy" @click="toggleOff">
          privacy notice
        </Go>
      </CursorWrap>
    </div>
  </div>
</template>

<script>
import LogoInstagram from '@/assets/images/logo-instagram.svg';
import LogoLinkedIn from '@/assets/images/logo-linkedin.svg';
import LogoFacebook from '@/assets/images/logo-facebook.svg';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import CursorWrap from '@/components/utils/CursorWrap.vue';

export default {
  components: {
    CursorWrap,
    LogoInstagram,
    LogoLinkedIn,
    LogoFacebook,
  },
  props: {
    dark: Boolean,
    classOverride: String,
  },
  emits: ['toggleOff'],
  setup() {
    return {
      isXsScreen: useWithinBreakpoint('xs'),
      isSmScreen: useWithinBreakpoint('sm'),
      isMdScreen: useWithinBreakpoint('md'),
      isLgScreen: useWithinBreakpoint('lg'),
      isXlScreen: useWithinBreakpoint('xl'),
    };
  },
  computed: {
    footer() {
      return this.$store.state.router.layout.footer;
    },
    dynamicColor() {
      return `var(--color-${this.dark ? 'white' : 'black'}-50)`;
    },
    dynamicHoverColor() {
      return `var(--color-${this.dark ? 'white' : 'blue'})`;
    },
  },
  methods: {
    toggleOff() {
      this.$emit('toggleOff');
    },
  },
};
</script>

<style scoped lang="scss">

.FooterLinks {
  @apply absolute bottom-18 left-0 flex gap-7 self-end z-[9999];
  .link {
    svg {
      @apply w-5.5;
      fill: v-bind(dynamicColor);
      transition: fill 0.25s ease-in-out;
    }
    &:hover svg {
      fill: v-bind(dynamicHoverColor);
    }
  }

  .footer-right {
    @apply ml-auto text-black-light flex gap-14 justify-end;
    @include mdLess {
      @apply gap-6;
    }
    color: v-bind(dynamicColor);
    a:hover {
      color: v-bind(dynamicHoverColor);
    }
  }

}

</style>
