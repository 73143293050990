<template>
  <div id="nav" class="Nav constrain" :class="{dark: dark && !opened, background: opened}">

    <CursorWrap cursor="link" :dark="dark && !opened">
      <div
        class="menu-toggle flex gap-5 cursor-pointer CaptureLink"
        :class="{dark: dark && !opened}"
        @click="toggle()"
        @mouseover="toggleHover(true)"
        @focusin="toggleHover(true)"
        @mouseleave="toggleHover(false)"
        @focusout="toggleHover(false)"
      >
        <Hamburger
          :class="{ opened: opened, hovered, dark: dark && !opened }"
          class="CaptureLink"
        />

        <div
          class="menu-btn-text"
          :class="{dark: dark && !opened}"
        >
          <span :class="{hiddenText: !opened}">
            Close.
          </span>
          <span :class="{hiddenText: opened}">
            Menu.
          </span>
        </div>

      </div>
    </CursorWrap>

    <CursorWrap cursor="link" :dark="dark && !opened">
      <Logo
        :class="{dark: dark && !opened }"
        @toggleOff="toggle(true)"
      />
    </CursorWrap>

  </div>

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: opened }" />

    <Menu :opened="opened" @toggleOff="toggle(true)" />
  </div>
</template>

<script>

import Scrolllock from '@/utils/scrolllock';
import Menu from '@/components/structure/Menu';
import CursorWrap from '@/components/utils/CursorWrap.vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import Hamburger from '../UI/Hamburger.vue';
import Logo from '../UI/Logo.vue';

export default {
  components: {
    CursorWrap,
    Menu,
    Hamburger,
    Logo,
  },
  setup() {
    return {
      isSmScreen: useWithinBreakpoint('sm'),
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    opened: false,
    transitioning: false,
    hovered: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    dark() {
      return this.$store.state.theme.dark;
    },
  },
  watch: {
    opened() {
      const listenForEscape = (e) => {
        if (e.key === 'Escape') {
          this.toggle(true);
        }
      };
      if (this.opened) {
        setTimeout(() => this.$store.commit('theme/lightenTheme'), this.isSmScreen ? 0 : 500);
        window.addEventListener('keydown', listenForEscape);
      } else {
        setTimeout(() => this.$store.commit('theme/darkenTheme'), 0);
        window.removeEventListener('keydown', listenForEscape);
      }
    },
  },
  methods: {
    toggle(close) {
      if (close || this.opened) {
        this.opened = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.opened = true;
        setTimeout(() => this.lock(), 400);
      }
    },
    toggleHover(state = !this.hovered) {
      this.hovered = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.Nav {
  @apply flex items-center py-16 pb-8 fixed top-0 z-100 w-full;
  transition: background-color 0.25s ease-in-out;
  @include smLess {
    @apply py-14 px-9;
    &.background {
      @apply bg-gradient-to-b from-white to-transparent;
    }
  }

  .menu-toggle {

    .menu-btn-text {
      @apply relative text-blue;
      transition: text-shadow 0.25s ease-in-out;
      text-shadow: 0 0 3px var(--color-white);

      &.dark {
        @apply text-white;
        text-shadow: 0 0 3px var(--color-blue);
      }

      span {
        @apply transition-opacity duration-300 absolute top-0 left-0 opacity-100;

        &.hiddenText {
          @apply opacity-0;
        }
      }
    }
  }
}

.link {
  &.active {
    text-decoration: underline;
  }
}

/* MOBILE NAV */
.backdrop {
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: transparent;
  transition: 0.3s ease-in-out;
  pointer-events: none;
  &.active {
    //background: var(--color-white);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}
</style>
