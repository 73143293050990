<template>
  <div class="Bars w-full h-full flex pointer-events-none absolute top-0 left-0">

    <template v-if="isSmScreen">
      <VerticalBar position="17.5%" :dark="dark" :full-height="fullHeight" />
      <VerticalBar position="50%" :dark="dark" :full-height="fullHeight" />
      <VerticalBar position="82.5%" :dark="dark" :full-height="fullHeight" />
    </template>
    <template v-else>
      <VerticalBar position="36%" :dark="dark" :full-height="fullHeight" />
      <VerticalBar position="50%" :dark="dark" :full-height="fullHeight" />
      <VerticalBar position="64%" :dark="dark" :full-height="fullHeight" />
      <VerticalBar position="78%" :dark="dark" :full-height="fullHeight" />
    </template>

  </div>
</template>

<script>
import VerticalBar from '@/components/UI/VerticalBar';
import { useWithinBreakpoint } from '@/hooks/breakpoints';

export default {
  components: {
    VerticalBar,
  },
  props: {
    dark: Boolean,
    fullHeight: Boolean,
  },
  setup() {
    return {
      isSmScreen: useWithinBreakpoint('sm'),
    };
  },
};
</script>

<style scoped>

</style>
