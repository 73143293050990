window.lockedOn = -1;

function lock() {
  if (window.lockedOn > -1) return;
  const main = document.querySelector('main');
  window.lockedOn = window.pageYOffset;
  main.style.top = `${-window.lockedOn}px`;
  main.style.position = 'fixed';
  main.style.opacity = 0;
}

function unlock() {
  if (window.lockedOn === -1) return;
  const main = document.querySelector('main');
  main.style.position = '';
  main.style.top = '';
  window.scrollTo({ top: window.lockedOn, left: 0, behavior: 'smooth' });
  window.lockedOn = -1;
  main.style.opacity = null;
}

export default {
  lock,
  unlock,
};
