const initialState = () => ({
  dark: true,
  cursorType: 'normal',
});

const mutations = {
  darkenTheme(state) {
    state.dark = true;
  },
  lightenTheme(state) {
    state.dark = false;
  },
  cursor(state, newCursor) {
    state.cursorType = newCursor;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
};
