<template>

  <template v-for="part of parts" :key="part.id">

    <slot
      v-if="$slots[part.template]"
      :id="part.slug"
      :name="part.template"
      :part="part"
    />

    <component
      :is="part.template"
      v-else-if="$options.components[part.template]"
      :id="part.slug"
      :payload="part"
    />

    <div v-else class="constrain">
      <strong>Unregistered Part «{{ part.template }}»!</strong>
    </div>

  </template>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),

    Homepage: defineAsyncComponent(() => import('@/components/structure/Pages/Homepage.vue')),
    Videopage: defineAsyncComponent(() => import('@/components/structure/Pages/Videopage.vue')),
    Page: defineAsyncComponent(() => import('@/components/structure/Pages/Page.vue')),
    Imprint: defineAsyncComponent(() => import('@/components/structure/Pages/InfoPage.vue')),
    Privacy: defineAsyncComponent(() => import('@/components/structure/Pages/InfoPage.vue')),

    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),
  },
  props: {
    parts: { type: Array, required: true },
    transition: { type: Boolean, default: true },
  },
  watch: {
    parts() {
      if (['Videopage', 'Homepage'].includes(this.parts[0]?.component)) this.$store.commit('theme/darkenTheme');
      else this.$store.commit('theme/lightenTheme');
    },
  },
};
</script>
