<template>
  <div
    class="hamburger"
  >
    <div class="bar bar1" />
    <div class="bar bar2" />
    <div class="bar bar3" />
  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.hamburger {
  @apply flex flex-col gap-1.5 w-9 h-12;

  .bar {
    @apply w-full bg-blue;
    cursor: var(--cursor);
    height: 3px;
    transition: all 0.25s cubic-bezier(0.18, 0.07, 0.83, 0.91);
    border-radius: 3px;
    box-shadow: 0 0 2px 0 var(--color-white);

    &.bar1 {
      @apply w-3/5 translate-x-0;
    }

    &.bar2 {
      //transition: all 0.25s linear;
      @apply mx-auto;
    }

    &.bar3 {
      @apply w-3/5 translate-x-3.5;
    }
  }

  &.opened {
    .bar {

      &.bar1 {
        @apply w-full rotate-45 translate-y-2.5 translate-x-0;
      }
      &.bar2 {
        @apply opacity-0 w-0;
      }
      &.bar3 {
        @apply w-full -rotate-45 -translate-y-2 -translate-x-0;
      }
    }
  }
  &.hovered {
    .bar1 {
      @apply translate-x-3.5;
    }
    .bar3 {
      @apply translate-x-0;
    }
  }

  &.dark {
    .bar {
      @apply bg-white;
      box-shadow: 0 0 2px 0 var(--color-blue);
    }
  }

}
</style>
