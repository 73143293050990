<template>

  <VerticalBars :dark="dark" />

  <div id="overlay" />
  <Teleport to="body">
    <div
      id="cursor"
      :class="[$store.state.theme.cursorType && $store.state.theme.cursorType]"
    >
      <div class="effects">

        <TransitionGroup :key="cursorType" name="fade">
          <SlideLeftIcon v-if="cursorType.startsWith('slide-left')" class="effect" />
          <SlideRightIcon v-if="cursorType.startsWith('slide-right')" class="effect" />
          <SlideCenterIcon v-if="cursorType.startsWith('slide-center')" class="effect" />
          <VideoPlayIcon v-if="cursorType.startsWith('video-play')" class="effect video" />
          <VideoPauseIcon v-if="cursorType.startsWith('video-pause')" class="effect video" />
        </TransitionGroup>

      </div>
    </div>
  </Teleport>

  <Navigation />

  <main>
    <Dynamic v-if="content" :parts="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';
import VerticalBars from '@/components/UI/VerticalBars';
import SlideLeftIcon from '@/assets/images/cursors/slide-left.svg';
import SlideRightIcon from '@/assets/images/cursors/slide-right.svg';
import SlideCenterIcon from '@/assets/images/cursors/slide-center.svg';
import LinkIcon from '@/assets/images/cursors/link.svg';
import VideoPlayIcon from '@/assets/images/cursors/video-play.svg';
import VideoPauseIcon from '@/assets/images/cursors/video-pause.svg';

export default {
  components: {
    Navigation,
    Dynamic,
    VerticalBars,
    SlideLeftIcon,
    SlideRightIcon,
    SlideCenterIcon,
    LinkIcon,
    VideoPlayIcon,
    VideoPauseIcon,
  },

  setup() {
    dynamicPage();
  },

  data() {
    return {
      isDarkBackground: false,
    };
  },

  computed: {
    dark() {
      return this.$store.state.theme.dark;
    },
    content() {
      const cont = this.$store.state.router.page?.content;
      if (!cont) this.$store.commit('theme/lightenTheme');

      return cont;
    },
    cursorType() {
      return this.$store.state.theme.cursorType;
    },
  },
  mounted() {
    this.$store.commit('theme/cursor', 'normal');

    window.addEventListener('mousemove', (e) => {
      const cursor = document.querySelector('#cursor');
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    });
  },
};
</script>

<style lang="scss">
#app {
}

#cursor {
  display: none;
  @media (pointer: fine) {
    @screen sm {
      display: flex;
    }
  }
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #FFF;
  transform: translateX(-50%) translateY(-50%);
  z-index: 99999;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
  &.link, &.link-dark {
    mix-blend-mode: difference;
  }
  &.normal {
    border-color: var(--color-blue);
    background-color: transparent;
  }
  &.normal-dark {
    border-color: var(--color-white);
    background-color: transparent;
  }
  &.video-play, &.video-pause {
    border-color: transparent;
    background-color: #fff;
  }
  &.slide-left, &.slide-right, &.slide-center {
    border-color: transparent;
    background-color: #fff;
  }

  .effects {
    width: 20px;
    height: 20px;
    //mix-blend-mode: difference;
    //transform: translateX(45px) translateY(45px);
    z-index: 99999;
    pointer-events: none;

    .effect {
      width: 100%;
      height: 100%;
      fill: var(--color-blue);
      &.effectLink {
        width: 125%;
        height: 125%;
        transform: translateX(25%) translateY(25%);
      }
      &.video {
        width: 125%;
        height: 125%;
        transform: translateX(-10%) translateY(-10%);
      }
    }

  }
}
</style>
