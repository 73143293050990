/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';

function elem(instance) {
  if (instance instanceof HTMLElement) return instance;
  return instance?.$el || instance;
}

export function useIntersectionObserver(entryCb, opts) {
  const elementRef = ref(null);

  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      entryCb(entry);
    }
  }, opts);

  onMounted(() => {
    observer.observe(elem(elementRef.value));
  });

  onUnmounted(() => {
    observer.unobserve(elem(elementRef.value));
  });

  return elementRef;
}

export function useResizeObserver(entryCb, opts) {
  const elementRef = ref(null);

  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      entryCb(entry);
    }
  }, opts);

  onMounted(() => {
    observer.observe(elem(elementRef.value));
  });

  onBeforeUnmount(() => {
    const element = elem(elementRef.value);
    if (element) {
      observer.unobserve(element);
    }
  });

  return elementRef;
}

export function useRootResizeObserver(entryCb, opts) {
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      entryCb(entry);
    }
  }, opts);

  onMounted(() => {
    observer.observe(document.body);
  });

  onUnmounted(() => {
    observer.unobserve(document.body);
  });
}

export function useWindowSize(cb) {
  const size = ref(window.innerWidth);

  const onResize = () => {
    size.value = window.innerWidth;
    if (cb) cb(size.value);
  };

  onMounted(() => {
    window.addEventListener('resize', onResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  return size;
}
