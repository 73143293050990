/* eslint-disable import/prefer-default-export */
import tailwindConfig from '../../tailwind.config';

export function config() {
  return tailwindConfig;
}

export function theme() {
  return tailwindConfig.theme;
}

export function breakpointSize(name) {
  return parseInt(theme().screens[name], 10);
}
