<template>
  <span v-if="!to">
    <slot />
  </span>

  <a
    v-else-if="isAnchor(to)"
    v-scroll-to="isAnchor(to)"
    :href="isAnchor(to)"
    class="cursor-pointer CaptureLink"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('http')"
    class="CaptureLink"
    :href="to"
    rel="nofollow noopener"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('tel:') || to.startsWith('mailto:')"
    class="CaptureLink"
    href="#"
    @click.prevent="special(to)"
  >
    <slot />
  </a>

  <a
    v-else
    :href="to"
    class="CaptureLink"
    @click="internal($event)"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    to: { type: String, default: null },
    scrollfirst: Boolean,
  },
  emits: ['click'],
  methods: {
    isAnchor(to) {
      if (to.startsWith('#')) return to;
      return false;
    },
    special(to) {
      this.$emit('click');
      window.location.href = to.replace(/ /g, '');
    },
    internal(e) {
      this.$emit('click', e);
      if (e.which === 2 || e.button === 4 || e.ctrlKey || e.metaKey) return;
      if (e.defaultPrevented) return;
      e.preventDefault();
      let delay = 0;
      if (window.scrollY > window.clientHeight && this.scrollfirst) {
        delay = 250;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      setTimeout(() => {
        this.$store.dispatch('router/push', { uri: this.to });
      }, delay);
    },
  },
};
</script>
